body {
    background-image: url('./img/picture1.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
}

.general {
    padding-top: 8vh;
}

.lines {
    display: flex;
    flex-direction: row;
}
.line1 {
    position: relative;
    width: 4px;
    height: 75px;
    background: white;
    top: -10vh; 
    left: 30.18vw;
    transform: rotate(45deg);
    -webkit-animation: line1 2s linear 0.5s forwards;
    animation: line1 2s linear 0.5s forwards;
}
@media only screen and (max-width: 700px) {
    .line1 {
        height: 60px;
        width: 3.2px;
    }
}
@keyframes line1 {
    0% { top: -10vh; left: 30.18vw; height: 75px; transform: rotate(45deg); opacity: 1 }
    15% { top: -10vh; left: 30.18vw; height: 200px; transform: rotate(45deg); opacity: 1 }
    25% { top: -10vh; left: 30.18vw; height: 200px; transform: rotate(45deg); opacity: 1 }
    40% { top: 0vh; left: 40.18vw; height: 200px; transform: rotate(45deg); opacity: 1 }
    55% { top: 10vh; left: 50.18vw; height: 200px; transform: rotate(45deg); opacity: 1 }
    75% { top: 10vh; left: 50.18vw; height: 200px; transform: rotate(45deg); opacity: 1 }
    80% { top: 10vh; left: 50.18vw; height: 200px; transform: rotate(90deg); opacity: 1 }
    87% { top: 10vh; left: 50.18vw; height: 200px; transform: rotate(90deg); opacity: 1 }
    90% { top: 10vh; left: 50.18vw; height: 200px; transform: rotate(90deg); opacity: 0 }
    100% { top: -19vh; left: 50.18vw; height: 200px; transform: rotate(90deg); opacity: 0 }
}
@media only screen and (max-width: 700px) {
  @keyframes line1 {
    0% { top: -10vh; left: 30.18vw; height: 60px; transform: rotate(45deg); opacity: 1 }
    15% { top: -10vh; left: 30.18vw; height: 150px; transform: rotate(45deg); opacity: 1 }
    25% { top: -10vh; left: 30.18vw; height: 150px; transform: rotate(45deg); opacity: 1 }
    40% { top: 0vh; left: 40.18vw; height: 150px; transform: rotate(45deg); opacity: 1 }
    55% { top: 10vh; left: 50.18vw; height: 150px; transform: rotate(45deg); opacity: 1 }
    75% { top: 10vh; left: 50.18vw; height: 150px; transform: rotate(45deg); opacity: 1 }
    80% { top: 10vh; left: 50.18vw; height: 150px; transform: rotate(90deg); opacity: 1 }
    87% { top: 10vh; left: 50.18vw; height: 150px; transform: rotate(90deg); opacity: 1 }
    90% { top: 10vh; left: 50.18vw; height: 150px; transform: rotate(90deg); opacity: 0 }
    100% { top: -12.5vh; left: 50.18vw; height: 150px; transform: rotate(90deg); opacity: 0 }
  }
}
.line2 {
    position: relative;
    width: 4px;
    height: 75px;
    background: rgb(233, 181, 61);
    top: -10vh;
    left: 69.86vw;
    transform: rotate(135deg);
    -webkit-animation: line2 2s linear 0.5s forwards;
    animation: line2 2s linear 0.5s forwards;
}
@media only screen and (max-width: 700px) {
    .line2 {
        height: 60px;
        width: 3.2px;
    }
}
@keyframes line2 {
    0% { top: -10vh; left: 69.86vw; height: 75px; transform: rotate(135deg) }
    15% { top: -10vh; left: 69.86vw; height: 200px; transform: rotate(135deg) }
    25% { top: -10vh; left: 69.86vw; height: 200px; transform: rotate(135deg) }
    40% { top: 0vh; left: 59.86vw; height: 200px; transform: rotate(135deg) }
    55% { top: 10vh; left: 49.86vw; height: 200px; transform: rotate(135deg) }
    75% { top: 10vh; left: 49.86vw; height: 200px; transform: rotate(135deg) }
    80% { top: 10vh; left: 49.86vw; height: 200px; transform: rotate(90deg) }
    87% { top: 10vh; left: 49.86vw; height: 200px; transform: rotate(90deg) }
    90% { top: 10vh; left: 49.86vw; height: 200px; transform: rotate(90deg) }
    100% { top: -19vh; left: 49.86vw; height: 325px; transform: rotate(90deg) }
}
@media only screen and (max-width: 700px) {
  @keyframes line2 {
    0% { top: -10vh; left: 69.86vw; height: 60px; transform: rotate(135deg) }
    15% { top: -10vh; left: 69.86vw; height: 150px; transform: rotate(135deg) }
    25% { top: -10vh; left: 69.86vw; height: 150px; transform: rotate(135deg) }
    40% { top: 0vh; left: 59.86vw; height: 150px; transform: rotate(135deg) }
    55% { top: 10vh; left: 49.86vw; height: 150px; transform: rotate(135deg) }
    75% { top: 10vh; left: 49.86vw; height: 150px; transform: rotate(135deg) }
    80% { top: 10vh; left: 49.86vw; height: 150px; transform: rotate(90deg) }
    87% { top: 10vh; left: 49.86vw; height: 150px; transform: rotate(90deg) }
    90% { top: 10vh; left: 49.86vw; height: 150px; transform: rotate(90deg) }
    100% { top: -12.5vh; left: 49.86vw; height: 250px; transform: rotate(90deg) }
  }
}
.CathyPottery {
    position: relative;
    /* padding-left: 28.5vw; */
    text-align: center;
    font-size: 12vh;
    padding-top: 24vh;
    color: rgb(233, 181, 61);
    clip-path: inset(0px 50% 0px 50%);
    -webkit-clip-path: inset(0px 50% 0px 50%);
    -webkit-animation: title 0.5s linear 2.45s forwards;
    animation: title 0.5s linear 2.4s forwards;
}
@media only screen and (max-width: 700px) {
  .CathyPottery {
    font-size: 6vh;
  }
}
@keyframes title {
    0% { clip-path: inset(0px 50% 0px 50%); -webkit-clip-path: inset(0px 50% 0px 50%) }
    100% { clip-path: inset(0px 0px 0px 0px); -webkit-clip-path: inset(0px 0 0px 0) }
}
